
import React from 'react';

import "./SplashPage.css";
import {Grid} from "@mui/material";
import Interest from "../../bundles/interest/interest";
import SupportLocal from "../../bundles/support/support";

import ShopLocal from "../../images/shop-local.png";
import ElLocale from "../../images/ellocale_text_only.png";
import BackgroundImage from "../../components/image/BackgroundImage";

import IMAGE from "../../images/portsmith-image.jpg";
import MissionStatement from "../../bundles/mission/mission";
import ElFooter from "../../components/footer/elfooter";
import InterestPageFull from "../../bundles/interest/interest_complete_full";

class SplashPage extends React.Component {

    constructor(props) {
        super(props);

        this.state= {
            validated: false
        };
    }

    render() {

        return (
            <React.Fragment>
                <div className={"splash-bg-image"}>&nbsp;</div>
                <div className={"splash-container"}>
                    {
                        this.state.validated ? <InterestPageFull/> :
                            <React.Fragment>
                                <div className={"splash-img-margins splash-margin-norm splash-sized-small splash-small-center"}>
                                    <img className={"splash-ellocale-img"} alt={"El Locale"} src={ElLocale}/>
                                </div>
                                <div className={"splash-upmargin"}>
                                    <Grid container alignContent={"center"} textAlign={"center"}>
                                        <Grid item xs={12} sm={12} md={7}>
                                            <div className={"splash-font-info splash-textpane-width splash-margins splash-margin-top splash-small-invis"}>Welcome to the place where you easily shop local</div>
                                            <div className={"splash-margin-top splash-sized-small"}><img className={"splash-shop-local-img"} src={ShopLocal} alt={"Shop Local"}/></div>
                                            <div className={"splash-margins splash-margin-top splash-margin-bottom splash-margin-norm"}><SupportLocal/></div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5}>
                                            <div className={"splash-margin-norm"}>
                                                <Interest parent={this}/>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={"splash-small-invis"}>
                                    <BackgroundImage image={IMAGE} height={200} content={<MissionStatement/>}/>
                                </div>
                                <div>
                                    <ElFooter/>
                                </div>

                            </React.Fragment>
                    }


                </div>
            </React.Fragment>
        );
    }
}

export default SplashPage;