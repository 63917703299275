import './App.css';

import React from 'react';
import {BrowserRouter} from "react-router-dom";
import {Routes,Route} from "react-router-dom";

import TestScreen from "./testing/tests/TestScreen";
import TestFrame from "./testing/tests/TestFrame";
import LoginPage from "./pages/login/LoginPage";
import ElHeader from "./components/header/elheader";
import ElFooter from "./components/footer/elfooter";
import ELInterest from "./bundles/interest/interest";
import SplashPage from "./pages/splash/SplashPage";
import BackgroundImage from "./components/image/BackgroundImage";

import IMAGE from "../src/images/portsmith-image.jpg";
import AuthTest from "./testing/tests/AuthTest";
import Unsubscribe from "./pages/unsubscribe/Unsubscribe";
import ShopCard from "./components/card/shopcard";
import SideMenu from "./components/menu/SideMenu";
import ProductPage from "./pages/product/ProductPage";
import ItemCard from "./components/card/itemcard";
import LaunchHome from "./pages/launch/LaunchHome";
import Cart from "./pages/cart/Cart";
import Payment from "./pages/payment/Payment";
import AccountCard from "./components/card/accountcard";
import VendorAccountPage from "./pages/vendor-account/VendorAccountPage";
import IndividualProduct from "./pages/product/IndividualProduct";
import OrderConfirmationHeader from "./components/orders/OrderConfirmationHeader";
import OrderDetailCard from "./components/card/orderdetailcard";
import OrderDetailsBundle from "./bundles/order/orderdetailsbundle";
import OrderConfirmation from "./pages/orders/OrderConfirmation";
import ProductViewport from "./components/pane/ProductViewport";
import BusinessPane from "./components/pane/BusinessPane";
import OrderDeliveryPage from "./bundles/order/order-delivery-page/OrderDeliveryPage";
import AddressPage from "./bundles/addresses/AddressPage";
import AddEditAddress from "./bundles/addresses/AddEditAddress";
import PaymentInformation from "./bundles/payment/PaymentInformation";
import DialogTestChooser from "./testing/tests/DialogTestChooser";
import ItemTablePane from "./business/inventory/ItemTablePane";
import TestModalLoader from "./testing/TestModalLoader";
import ChipContainer from "./business/components/ChipContainer";
import ItemDetails from "./business/items/tabs/ItemDetails/ItemDetails";
import TestPane from "./testing/TestPane";
import AboutUs from "./pages/about/AboutUs";
import ContactUs from "./pages/contactus/ContactUs";
import EditRow from "./components/edit/EditRow";
import LoginSecurity from "./pages/login/LoginSecurity";
import GiftCard from "./pages/giftcard/GiftCard";

function App() {

    let sideMenuLinks = [
                {
                    description: "Antiques",
                    href: "?category=antiques"
                },
                {
                    description: "Art",
                    href: "?category=art"
                }
    ];

    let items = [];
    let accountCards = [];
    let itemOptions = [];

    for (let i = 0; i < 17; i++) {
        items.push(<ItemCard key={"k_"+i}/>);
    }

    itemOptions.push({id: 124, text: "Small Wick"});
    itemOptions.push({id: 125, text: "Medium Wick"});
    itemOptions.push({id: 126, text: "Long Wick"});

    let options = {title: "Wick", itemOptions: itemOptions, required: true};

    accountCards.push(<AccountCard name={"My Account"} desc={"My Account Description"}/>);

  return (
      <BrowserRouter>
          <Routes>
              {/*<Route path={"/"} element={<LandingPage/>}/>*/}
              <Route path={"/testscreens/testmodals"} element={<TestModalLoader/>}/>
              <Route path={"/"} element={<SplashPage/>}/>
              <Route path={"/testscreens"} element={<TestScreen/>}/>
              <Route path={"/testscreens/login"} element={<LoginPage/>}/>
              <Route path={"/testscreens/header"} element={<ElHeader/>}/>
              <Route path={"/testscreens/footer"} element={<ElFooter/>}/>
              <Route path={"/testscreens/interest"} element={<ELInterest/>}/>
              <Route path={"/testscreens/business"} element={<ProductPage locale={"Portsmouth, NH"}
                                                                          description={"Locale Faire"}
                                                                          detailsPane={<BusinessPane/>}
                                                                          showMap={true}/>}/>

              <Route path={"/testscreens/product"} element={<ProductPage items={items}
                                                                         locale={"Portsmouth, NH"}
                                                                         description={"Locale Faire"}
                                                                         detailsPane={<ProductViewport items={items}/>}
              />}/>
              <Route path={"/testscreens/splash"} element={<SplashPage/>}/>
              <Route path={"/testscreens/launch"} element={<LaunchHome locale={"Portsmouth, NH"} description={"Locale Faire"}/>}/>
              <Route path={"/testscreens/authtest"} element={<AuthTest/>}/>
              <Route path={"/testscreens/cart"} element={<Cart/>}/>
              <Route path={"/unsubscribe"} element={<Unsubscribe/>}/>
              <Route path={"/testscreens/bgimage"} element={<BackgroundImage image={IMAGE}/>}/>
              <Route path={"/interest/confirm"} element={<ELInterest/>}/>
              <Route path={"/testscreens/shopcard"} element={<ShopCard/>}/>
              <Route path={"/testscreens/itemcard"} element={<ItemCard/>}/>
              <Route path={"/testscreens/sidemenu"} element={<SideMenu title={"Category"} links={sideMenuLinks}/>}/>
              <Route path={"/testscreens/payment"} element={<Payment/>}/>
              <Route path={"/testscreens/orderdeliverycard"} element={<OrderDeliveryPage/>}/>
              <Route path={"/testscreens/accountcard"} element={<TestFrame items={accountCards}/>}/>
              <Route path={"/testscreens/accountpage"} element={<VendorAccountPage/>}/>
              <Route path={"/testscreens/cards/orderdetailcard"} element={<OrderDetailCard/>}/>
              <Route path={"/testscreens/bundles/ordercarddetails"} element={<OrderDetailsBundle/>}/>
              <Route path={"/testscreens/orderconfirmation"} element={<OrderConfirmation/>}/>
              <Route path={"/testscreens/shippingaddresses"} element={<AddressPage/>}/>
              <Route path={"/testscreens/billingaddresses"} element={<AddressPage pageType={"Billing"}/>}/>
              <Route path={"/testscreens/addaddress"} element={<AddEditAddress mode={"Add"} type={"Shipping"}/>}/>
              <Route path={"/testscreens/editaddress"} element={<AddEditAddress mode={"Edit"} type={"Billing"}/>}/>
              <Route path={"/testscreens/paymentinfo"} element={<PaymentInformation/>}/>
              <Route path={"/testscreens/dialogs"} element={<DialogTestChooser/>}/>
              <Route path={"/testscreens/itemtablepane"} element={<ItemTablePane/>}/>
              <Route path={"/testscreens/chipcontainer"} element={<ChipContainer width={"800px"} height={"100%"}/>}/>
              <Route path={"/testscreens/aboutus"} element={<AboutUs/>}/>
              <Route path={"/testscreens/contactus"} element={<ContactUs/>}/>

              <Route path={"/testscreens/editrow"} element={<TestPane
                                                                content={<EditRow title={"This is a title"}
                                                                                  value={"This is a value"}
                                                                                  desc={"This is a fairly short description."}/>}/>}/>

              <Route path={"/testscreens/loginsecurity"} element={<LoginSecurity/>}/>
              <Route path={"/testscreens/giftcard"} element={<GiftCard/>}/>

              <Route path={"/testscreens/cards/orderconfirmationcard"}
                     element={<OrderConfirmationHeader
                        shipping={"511 My Address, Portsmouth, NH 03801"}
                        savings={"$4.99"}
                        ordernum={"1122-1234-123"}
                     />}/>
              <Route path={"/testscreens/individualproduct"}
                     element={<IndividualProduct
                                category={"Candles"}
                                subcategory={"Wish Candles"}
                                price={"$28.00"}
                                name={"11:11 Wish Candle"}
                                image={"https://ellocale-product.s3.us-west-2.amazonaws.com/wax-candle-124.png"}
                                desc={"Candle, light source now mostly used for decorative and ceremonial purposes, consisting of wax, tallow, or similar slow-burning material, commonly in cylindrical form but made in many fanciful designs, enclosing and saturating a fibrous wick."}
                                options={options}/>}/>

              {/*Item Details and Item Screens*/}
              <Route path={"/testscreens/edititemdetails"}
                     element={<TestPane height={650} content={<ItemDetails/>}/>}
              />
          </Routes>
      </BrowserRouter>

  );
}

export default App;
