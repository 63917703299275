import BusinessCard from "../components/card/businesscard";
import NoImage from "../images/noimage-white.png";

export const categories = [
    {description: "Antiques"},
    {description: "Art"},
    {description: "Clothing"},
    {description: "Furniture"},
    {description: "Home"},
    {description: "Jewelry"},
    {description: "Kitchen"},
    {description: "Pet"},
    {description: "Souveniers"},
    {description: "Tea"},
    {description: "Thrift"}
];

export const gifts = [
    {description: "Less than $50"},
    {description: "$50-$100"},
    {description: "$100-$250"},
    {description: "$250+"}
];

export const local = [
    {description: "Artisan"},
    {description: "Handcrafted"},
    {description: "Made in NH"},
    {description: "Made in ME"},
    {description: "Most Loved"},
    {description: "One of a Kind"}
];

export const myShops = [
    {description: "Evie"},
    {description: "Jane Gee"},
    {description: "Société"}
];

export const myLocales = [
    {description: "Dover"},
    {description: "Edgartown"},
    {description: "Kittery"},
    {description: "Nantucket"},
    {description: "Portsmouth"}
];

export const myBusinesses = [
    <BusinessCard name={"Evie"} desc={"Women's Clothing Boutique"} location={"Congress Street, Portsmouth, NH"} opens={"10am"}/>,
    <BusinessCard name={"Wear House"} desc={"Women's Luxury Second Hand Boutique"} location={"Market Street, Portsmouth, NH"} opens={"11am"}/>,
    <BusinessCard name={"Market Square Jewelers"} desc={"Vintage Jeweler and Repair"} location={"Market Street, Portsmouth, NH"} opens={"10am"}/>
];

export const SampleCategoryData = [
    {
        name: "Bath",
        id: 1,
        image: NoImage
    },
    {
        name: "Chakra",
        id: 2,
        image: NoImage
    },
    {
        name: "Herbs",
        id: 3,
        image: NoImage
    },
    {
        name: "Flower Essences",
        id: 4,
        image: NoImage
    },
    {
        name: "Essential Oil Singles",
        id: 5,
        image: NoImage
    },
    {
        name: "Beverages",
        id: 6,
        image: NoImage
    },
    {
        name: "General Items",
        id: 7,
        image: NoImage
    }
];

export const MOCK_ITEM_FIELDS = [
    "Item",
    "Sold By",
    "Stock",
    "Site",
    "Price",
    "Vendor",
    "Category"
];

export const MOCK_DATA_GRID_ROWS = [
    { id: 1, itemName: "1 oz. Black Dropper Cap", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 44, inInventory: 44, price: "$1.15/ea" },
    { id: 2, itemName: "1 oz. Black Pump", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 21, inInventory: 21, price: "Variable" },
    { id: 3, itemName: "1 oz. Cobalt Blue Glass", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 48, inInventory: 48, price: "$1.15/ea" },
    { id: 4, itemName: "1 oz. Cylinder Frosted Glass", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 119, inInventory: 119, price: "$1.15/ea" },
    { id: 5, itemName: "1 oz. Gold Pump", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 252, inInventory: 250, price: "Variable" },
    { id: 6, itemName: "1 oz. Round Amber Bottle", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 558, inInventory: 558, price: "$1.15/ea" },
    { id: 7, itemName: "1 oz. Round Clear Bottle", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 360, inInventory: 360, price: "$1.15/ea" },
    { id: 8, itemName: "1 oz. Black Amber Bottle", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 121, inInventory: 121, price: "$1.15/ea" },
    { id: 9, itemName: "1 oz. Black Dropper Cap", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 120, inInventory: 88, price: "$1.15/ea" },
    { id: 10, itemName: "5 oz. Bottle (Clear)", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 14, inInventory: 14, price: "$5.15/ea" },
    { id: 11, itemName: "Paginated Item", reportingCategory: "Inventory", locations: "Societe", soldBy: "ea", stock: 22, inInventory: 22, price: "$.15/ea" },
];

export const MOCK_DATA_TRACKING = [
    {name: "Jar", uid: "SKU 124928", quantity: 80, track: true, alertAt: 10, alert: true},
    {name: "Tea", uid: "GTIN 8 100070, SKU 55024", quantity: 140, track: false, alertAt: 50, alert: false},
    {name: "Storage", uid: "SKU 98192", quantity: 10, track: true, alertAt: 10, alert: true},
    {name: "Label", uid: "SKU 244222", quantity: 180, track: true, alertAt: 30, alert: false},
    {name: "Image", uid: "SKU 192837", quantity: 20, track: true, alertAt: 10, alert: true},
    {name: "Ruler", uid: "SKU 8675309", quantity: 5, track: true, alertAt: 10, alert: true},
    {name: "Glass", uid: "SKU 123456",quantity: 300, track: false, alertAt: 100, alert: true},
    {name: "Top", uid: "SKU 911911",quantity: 12, track: true, alertAt: 20, alert: true},
    {name: "Spring", uid: "SKU 2423",quantity: 155, track: true, alertAt: 10, alert: false},
    {name: "Grounds", uid: "SKU 2424",quantity: 1000, track: true, alertAt: 10, alert: true},
    {name: "Flavor", uid: "SKU 2426",quantity: 24, track: true, alertAt: 10, alert: true},
    {name: "Needle", uid: "SKU 876767",quantity: 17, track: true, alertAt: 10, alert: true},
];

export const MOCK_DATA_CATEGORIES = [
    {icon: null, name: "Bath", key: "bath", selected: true},
    {icon: null, name: "Chakra", key: "chakra", selected: false},
    {icon: null, name: "Herbs", key: "herbs", selected: true},
    {icon: null, name: "Flower Essenses", key: "flower_essenses", selected: false},
    {icon: null, name: "Essential Oil Singles", key: "essential_oil_singles", selected: false},
    {icon: null, name: "Beverages", key: "beverages", selected: false},
    {icon: null, name: "Face", key: "face", selected: false},
    {icon: null, name: "Whole Body", key: "whole_body", selected: false},
    {icon: null, name: "Addons", key: "addons", selected: true},
    {icon: null, name: "Options", key: "options", selected: false},
    {icon: null, name: "Furniture", key: "furniture", selected: false},
    {icon: null, name: "Other", key:"other", selected: false}
];

export const MOCK_DATA_FAVORITES = [
    {icon: NoImage, name: "Portsmouth", key: "locale_portsmouth", selected: true},
    {icon: NoImage, name: "Nantucket", key: "locale_nantucket", selected: true},
    {icon: NoImage, name: "San Diego", key: "locale_sandiego", selected: true},
    {icon: NoImage, name: "Societe", key: "shop_societe", selected: true},
    {icon: NoImage, name: "Salt and Spice", key: "shop_saltandspice", selected: true},
    {icon: NoImage, name: "Cup of Joe", key: "shop_cupofjoe", selected: true},
    {icon: NoImage, name: "Ceres Street Gallery", key: "shop_ceresstreetgallery", selected: true},
];

export const MOCK_DATA_GRID_COLUMNS = [
    {
        field: 'itemName',
        headerName: 'Item',
        width: 250,
        editable: true,
    },
    {
        field: 'reportingCategory',
        headerName: 'Category',
        width: 100,
        editable: true,
    },
    {
        field: 'locations',
        headerName: 'Locations',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'soldBy',
        headerName: 'Sold By',
        description: 'Each, Package, etc...',
        sortable: false,
        width: 80,
        // valueGetter: (value, row) => `${row.firstName || ''} ${row.lastName || ''}`,
    },
    {
        field: 'stock',
        headerName: 'Stock',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'inInventory',
        headerName: 'In Inventory',
        type: 'number',
        width: 110,
        editable: true,
    },
    {
        field: 'price',
        headerName: 'Price',
        type: 'number',
        width: 110,
        editable: true
    }
];

export const MOCK_ITEM_OPTIONS = [
    {
        name: "All Categories", items: SampleCategoryData
    },
    {
        name: "Active", items: [
            {
                id: 1,
                name: "Is Active"
            },
            {
                id: 2,
                name: "Temporarily Out of Stock"
            },
            {
                id: 3,
                name: "Inactive"
            },
            {
                id: 4,
                name: "No Longer Available"
            }
        ]
    }
];